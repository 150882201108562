@import "@bryntum/calendar/calendar.stockholm.css";

#root {
  height: 100vh;
}

.b-calendar {
  height: 100%;
  width: 100%;
}

.b-widget .b-button {
  border-radius: 10px;
}

.b-widget .b-button {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.b-recurrenceconfirmationpopup {
  width: auto !important;
}



  
  